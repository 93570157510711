import PartnerOrder from '@/domain/PartnerOrder';
import PartnerOrderApiService from './api/PartnerOrderApiService';
import ImportErrorService from './ImportErrorService';
import { getTranslation, getTitleCaseTranslation } from './TranslationService';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class PartnerOrderService extends ImportErrorService<PartnerOrder> {
    private apiService: PartnerOrderApiService = new PartnerOrderApiService();

    private readonly revalidationSuccessMsg = getTranslation('core.common.partnerOrdersHaveBeenQueuedUp');

    public async getAllFailed(): Promise<PartnerOrder[]> {
        const response = await this.apiService.getFailedPartnerOrders();

        return response.data;
    }

    public async getAllFailedCount(): Promise<number> {
        const count = await this.apiService.getFailedPartnerOrdersCount();

        return count;
    }

    public async rejectFailed(id: number): Promise<number> {
        const response = await this.apiService.rejectPartnerOrder(id);

        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.rejectedPartnerOrder'));
        }

        return response.data;
    }

    public async revalidateAllFailed(): Promise<number> {
        const response = await this.apiService.revalidateAllPartnerOrders();

        return this.getRevalidateAllResult(response, this.revalidationSuccessMsg, getTranslation('core.validation.allItemsContainOneOrMoreValidationErrorsThatNeedAddressing'));
    }

    public async revalidateFailed(id: number): Promise<string[]> {
        const response = await this.apiService.revalidatePartnerOrder(id);

        return this.getRevalidateResult(response, this.revalidationSuccessMsg);
    }

    public async getPartnerOrder(id: number): Promise<PartnerOrder> {
        const response = await this.apiService.getPartnerOrder(id);
        return response.data;
    }

    public async updatePartnerOrder(partnerOrder: PartnerOrder): Promise<DataAccessResponse<boolean>> {
        const response = await this.apiService.updatePartnerOrder(partnerOrder);

        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedPartnerOrder'));
        }

        return response;
    }
}
