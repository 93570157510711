
import {
    computed, defineComponent, onMounted, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import Location from '@/domain/Location';
import LocationService from '@/services/LocationService';
import PartnerOrderService from '@/services/PartnerOrderService';
import PartnerOrder from '@/domain/PartnerOrder';
import PartnerOrderItem from '@/domain/PartnerOrderItem';
import Carrier from '@/domain/Carrier';
import CarrierService from '@/modules/master-data/services/CarrierService';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import Item from '@/domain/Item';
import ItemService from '@/services/ItemService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import resetProp from '@/functions/object';

type State = {
    originalPartnerOrder: PartnerOrder | undefined;
    partnerOrder: PartnerOrder | undefined;
    locations: Array<Location>;
    carriers: Array<Carrier>;
    items: Array<Item>;
    loading: boolean;
    saving: boolean;
};

export default defineComponent({
    name: 'master-data-import-errors-po-update',
    components: {
        BRow,
        BCol,
        DropdownAutocompleteSingleSelect,
        SmartTrakFooter,
        BSpinner,
        TextInput,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const locationService = new LocationService();
        const carrierService = new CarrierService();
        const partnerOrderService = new PartnerOrderService();
        const itemService = new ItemService();

        const { titleCase } = useStringFormatter();
        const router = useRouter();

        const state = reactive<State>({
            originalPartnerOrder: undefined,
            partnerOrder: undefined,
            locations: [],
            carriers: [],
            items: [],
            loading: false,
            saving: false,
        });

        const pageTitle = computed(() => getTitleCaseTranslation('core.button.updateData') + (state.partnerOrder ? ` (${state.partnerOrder.partnerReferenceNumber})` : ''));

        const itemTableFields = computed(
            (): Array<BTableField<PartnerOrderItem>> => [
                {
                    key: 'itemNumber',
                    label: getTitleCaseTranslation('core.domain.itemNumber'),
                    ignoreSort: true,
                },
                {
                    key: 'quantity',
                    label: getTitleCaseTranslation('core.domain.quantity'),
                    ignoreSort: true,
                },
            ],
        );

        function locationExists(locationNumber: string | undefined) {
            return !!(locationNumber && state.locations.find((x) => x.locationNumber?.toLowerCase() === locationNumber?.toLowerCase()));
        }

        function carrierExists(scac: string | undefined) {
            return !!(scac && state.carriers.find((x) => x.scac?.toLowerCase() === scac?.toLowerCase()));
        }

        function itemExists(itemNumber: string | undefined) {
            return !!(itemNumber && state.items.find((x) => x.customerItemNumber?.toLowerCase() === itemNumber?.toLowerCase()));
        }

        onMounted(async () => {
            state.loading = true;

            const [locationsResponse, carriersResponse, itemsResponse, partnerOrderReponse] = await Promise.all([
                locationService.getAllLocations(),
                carrierService.getAllCarriers(),
                itemService.getAllItems(),
                partnerOrderService.getPartnerOrder(parseInt(props.id, 10)),
            ]);

            if (locationsResponse.success) {
                state.locations = locationsResponse.locations.filter((x) => x.locationNumber);
            }
            if (carriersResponse.success) {
                state.carriers = carriersResponse.carriers.filter((x) => x.scac);
            }
            if (itemsResponse.success) {
                state.items = itemsResponse.items.filter((x) => x.customerItemNumber);
            }

            if (partnerOrderReponse) {
                // store original values for displaying in labels
                state.originalPartnerOrder = new PartnerOrder(partnerOrderReponse);
                state.partnerOrder = partnerOrderReponse;

                // clear out any values that are invalid
                if (!locationExists(state.partnerOrder.fromLocation)) {
                    state.partnerOrder.fromLocation = '';
                }
                if (!locationExists(state.partnerOrder.toLocation)) {
                    state.partnerOrder.toLocation = '';
                }
                if (!carrierExists(state.partnerOrder.carrier)) {
                    state.partnerOrder.carrier = '';
                }
                state.partnerOrder.partnerOrderItems.forEach((x) => {
                    if (x.itemNumber && !itemExists(x.itemNumber)) {
                        x.itemNumber = '';
                    }
                });
            }

            state.loading = false;
        });

        function selectFromLocation(location: Location) {
            if (state.partnerOrder) {
                state.partnerOrder.fromLocation = location?.locationNumber ?? '';
            }
        }

        function selectToLocation(location: Location) {
            if (state.partnerOrder) {
                state.partnerOrder.toLocation = location?.locationNumber ?? '';
            }
        }

        function selectCarrier(carrier: Carrier) {
            if (state.partnerOrder) {
                state.partnerOrder.carrier = carrier?.scac ?? '';
            }
        }

        function findOriginalItemNumber(orderItemId: number) {
            return state.originalPartnerOrder?.partnerOrderItems?.find((x: PartnerOrderItem) => x.id === orderItemId)?.itemNumber;
        }

        function selectItem(partnerOrderItem: PartnerOrderItem, item: Item) {
            if (!state.partnerOrder || !partnerOrderItem) {
                return;
            }

            partnerOrderItem.itemNumber = item?.customerItemNumber ?? '';
        }

        function clearItem(partnerOrderItem: PartnerOrderItem) {
            partnerOrderItem.itemNumber = '';
        }

        function goToPartnerOrderList() {
            router.push({ name: MasterDataRouteTypes.IMPORT_ERRORS.PO });
        }

        async function save() {
            if (!state.partnerOrder) {
                return;
            }

            // reset any (editable) empty fields to the original value
            ['fromLocation', 'toLocation', 'carrier'].forEach((x) => resetProp(x, state.partnerOrder, state.originalPartnerOrder));
            state.partnerOrder.partnerOrderItems.forEach((item, index) => {
                resetProp('itemNumber', item, state.originalPartnerOrder?.partnerOrderItems[index]);
            });

            state.saving = true;
            const response = await partnerOrderService.updatePartnerOrder(state.partnerOrder);
            if (response?.success) {
                goToPartnerOrderList();
            }
            state.saving = false;
        }

        return {
            state,
            pageTitle,
            selectToLocation,
            selectCarrier,
            selectFromLocation,
            titleCase,
            getTitleCaseTranslation,
            getTranslation,
            goToPartnerOrderList,
            save,
            itemTableFields,
            selectItem,
            clearItem,
            locationExists,
            carrierExists,
            itemExists,
            findOriginalItemNumber,
        };
    },
});
