
import { defineComponent, PropType } from 'vue';
import DropdownAutocompleteBase from '@/components/dropdown/DropdownAutocompleteBase.vue';

export default defineComponent({
    name: 'dropdown-autocomplete-single-select',
    components: { DropdownAutocompleteBase },
    extends: DropdownAutocompleteBase,
    props: {
        onBeforeClear: Function as PropType<() => Promise<boolean>>,
        selectOnEnter: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'onSelect', 'onClear'],
    setup(props, context) {
        function handleSelect(value: unknown | null) {
            if (value) {
                // @ts-ignore
                context.emit('update:modelValue', value[props.searchBy]);
                context.emit('onSelect', value);
            } else {
                context.emit('update:modelValue', null);
                context.emit('onSelect', null);
            }
        }

        function handleClear() {
            context.emit('onClear');
        }

        return {
            handleSelect,
            handleClear,
        };
    },
});
