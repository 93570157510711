import PartnerOrderStatus from './enums/PartnerOrderStatus';
import ImportError from './ImportError';
import PartnerOrderItem from './PartnerOrderItem';

export default class PartnerOrder implements ImportError {
    public id: number;

    public fromLocation?: string;

    public toLocation: string;

    public dateRequired?: Date;

    public carrier: string;

    public trailerNumber: string;

    public partnerReferenceNumber: string;

    public plannedDepartureDateTime?: Date;

    public carrierReferenceNumber?: string;

    public licensePlateNumber?: string;

    public seal?: string;

    public createdOn: Date;

    public changedOn: Date;

    public partnerOrderItems: PartnerOrderItem[];

    public billToLocation?: string;

    constructor(init?: Partial<PartnerOrder>) {
        this.id = init?.id || 0;
        this.fromLocation = init?.fromLocation || '';
        this.toLocation = init?.toLocation || '';
        this.dateRequired = init?.dateRequired ? new Date(init.dateRequired) : new Date();
        this.carrier = init?.carrier || '';
        this.trailerNumber = init?.trailerNumber || '';
        this.partnerReferenceNumber = init?.partnerReferenceNumber || '';
        this.plannedDepartureDateTime = init?.plannedDepartureDateTime ? new Date(init.plannedDepartureDateTime) : new Date();
        this.carrierReferenceNumber = init?.carrierReferenceNumber;
        this.licensePlateNumber = init?.licensePlateNumber;
        this.seal = init?.seal;
        this.createdOn = init?.createdOn ? new Date(init.createdOn) : new Date();
        this.changedOn = init?.changedOn ? new Date(init.changedOn) : new Date();
        this.partnerOrderItems = init?.partnerOrderItems?.map((x) => new PartnerOrderItem(x)) || [];
        this.billToLocation = init?.billToLocation || '';
    }

    public get failedPartnerOrderItems(): PartnerOrderItem[] {
        return this.partnerOrderItems.filter((item) => item.status === PartnerOrderStatus.Failed);
    }
}
