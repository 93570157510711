import PartnerOrderStatus from './enums/PartnerOrderStatus';

export default class PartnerOrderItem {
    public id: number;

    public partnerOrderId: number;

    public itemNumber: string;

    public quantity: number;

    public status: PartnerOrderStatus;

    public transactionId?: number;

    public createdOn: Date;

    public changedOn: Date;

    constructor(init?: Partial<PartnerOrderItem>) {
        this.id = init?.id || 0;
        this.partnerOrderId = init?.partnerOrderId || 0;
        this.itemNumber = init?.itemNumber || '';
        this.quantity = init?.quantity || 0;
        this.status = init?.status || PartnerOrderStatus.Pending;
        this.transactionId = init?.transactionId;
        this.createdOn = init?.createdOn ? new Date(init.createdOn) : new Date();
        this.changedOn = init?.changedOn ? new Date(init.changedOn) : new Date();
    }
}
